<script>
import ScopeTable from '../components/ScopeTable.vue';

export default {
  name: 'ShowRegister',
  components: {
    ScopeTable,
  },
  data() {
    return {};
  },
  computed: {
    viewer() {
      return this.$store.state.projectsViewer;
    },
    editor() {
      return this.$store.state.projectsEditorDb;
    },
    owner() {
      return this.$store.state.projectsOwner;
    },
    availableProjects() {
      return this.$store.getters.projectsViewable;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    projectId() {
      return this.$store.state.route.params.projectId;
    },
    projectName() {
      return this.$store.scopes[0].project.name;
    },
    scopeNumber() {
      return this.$store.state.route.params.scopeNumber;
    },
    userId() {
      return this.$store.state.userId;
    },
    projectNameDisplay() {
      const mode = this.projectNameDisplaySetting;
      const project = this.selectedProject[0];

      const code = mode.includes(0) ? project.code : '',
        key = mode.includes(1) ? project.projectId : '',
        name = mode.includes(2) ? project.name : '';

      let displayName = [code ? code : '', key ? key : '', name ? name : ''];

      if (mode.length === 0) {
        displayName = [project.name];
      }

      return displayName.filter(p => p).join(' ');
    },
    projectNameDisplaySetting() {
      return this.$store.state.projectNameDisplay;
    },
  },
  created() {},
  methods: {
    selectProject(value) {
      this.$store.dispatch('clearScopeId').then(() => {
        this.$router.push({
          name: 'register',
          params: {
            ...value,
          },
        });
      });
    },
  },
};
</script>

<template>
  <div>
    <div v-if="selectedProject[0]" class="text-h6 px-3 pt-3">
      {{ selectedProject[0] && projectNameDisplay }}
    </div>
    <ScopeTable />
  </div>
</template>
