<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ScopeFactsCard',
  components: {
    MeetingDatePicker: () => import('../components/MeetingDatePicker.vue'),
    FocusChipField: () => import('../components/FocusChipField.vue'),
  },
  props: {
    item: { type: Object, default: () => {} },
    doc: { type: Object, default: () => {} },
    focusKey: { type: String, default: '' },
    index: { type: Number, default: 0 },
    focusCount: { type: Number, default: 0 },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      projectId: state => state.projectId,
      scopeEditMode: state => state.scopeEditMode,
      currentEditDocId: state => state.currentEditDocId,
      requiresApproval: state => (state.selectedProject[0] || {}).approval,
      availableValues: state => (state.selectedProject[0] || {}).fieldValues,
      previousValues: state => (state.selectedProject[0] || {}).usedFieldValues,
      isGrfn: state => state.isGrfn,
    }),
    hasDetails() {
      return Object.keys(this.item).length > 1;
    },
    hasLogDetails() {
      const properties = Object.keys(this.item);
      const logProperties = properties.filter(key => key.indexOf('log') === 0);
      return logProperties.length >= 1;
    },
    pastEvent() {
      const d = this.meetingDate;
      const now = new Date();

      if (d.toDate) {
        return d.toDate() < now;
      }
      return false;
    },
    isOwner: {
      get() {
        return (
          this.$store.getters.projectsOwned.filter(
            p => p.projectId === this.projectId
          ).length > 0
        );
      },
    },
    config() {
      const config = { ...this.appConfig };
      const streams = config.streams ? [...config.streams] : [];
      config.streams = this.analysisUnits || streams;
      return config;
    },
    disabled() {
      return !this.scopeEditMode;
    },
    editMode() {
      return this.scopeEditMode;
    },
    multipleFocus() {
      return this.$parent.focus && this.$parent.focus.length > 1;
    },
    summary: {
      get() {
        return this.item.summary;
      },
      set(v) {
        this.updateFacts('summary', v.trim());
      },
    },
    meetingDate: {
      get() {
        return this.item.meetingDate;
      },
      set(v) {
        this.updateFacts('meetingDate', v);
        if (!this.item.uploadDate) {
          const calculatedUploadDate = new Date(v.setDate(v.getDate() - 7));
          this.updateFacts('uploadDate', calculatedUploadDate);
        }
      },
    },
    uploadDate: {
      get() {
        return this.item.uploadDate;
      },
      set(v) {
        this.updateFacts('uploadDate', v);
      },
    },
    approved: {
      get() {
        return this.item.approved;
      },
      set(v) {
        this.updateFacts('approved', v);
      },
    },
    stream: {
      get() {
        return this.item.stream;
      },
      set(v) {
        this.updateFacts('stream', v);
      },
    },
    notes: {
      get() {
        return this.item.notes;
      },
      set(v) {
        this.updateFacts('notes', v.trim());
      },
    },
    notesFormatted() {
      return this.notes.replace(/[\r\n]/g, '<br />');
    },
    attendance: {
      get() {
        return this.item.attendance;
      },
      set(v) {
        this.updateFacts('attendance', v);
      },
    },
    logAttendees: {
      get() {
        return this.item.logAttendees;
      },
      set(v) {
        this.updateFacts('logAttendees', v);
      },
    },
    logIssuesRaised: {
      get() {
        return this.item.logIssuesRaised;
      },
      set(v) {
        this.updateFacts('logIssuesRaised', v);
      },
    },
    logScopesReviewed: {
      get() {
        return this.item.logScopesReviewed;
      },
      set(v) {
        this.updateFacts('logScopesReviewed', v);
      },
    },
    analyst: {
      get() {
        return this.item.analyst;
      },
      set(v) {
        this.updateFacts('analyst', v);
      },
    },
    number: {
      get() {
        return this.item.number;
      },
      set(v) {
        this.updateFacts('number', parseInt(v, 10));
      },
    },
    ...mapGetters(['getProjectConfiguration', 'appConfig']),

    analysisUnits() {
      return this.getProjectConfiguration.analysisUnits;
    },
    streamsTitle() {
      return this.getProjectConfiguration.streamsTitle;
    },
  },
  methods: {
    validURL(str) {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ); // fragment locator
      return !!pattern.test(str);
    },
    hasValues(field) {
      let hasLength = true;

      if (this.item[field] && this.item[field].trim) {
        hasLength = this.item[field].trim().length > 0;
      }

      return this.item[field] && hasLength;
    },
    onMeetingDateChanged(e) {
      this.meetingDate = new Date(e);
    },
    onUploadDateChanged(e) {
      this.updateFacts('uploadDate', new Date(e));
    },
    updateFacts(field, value) {
      this.$store.dispatch('updateFacts', {
        db: this.$db,
        value: value,
        field: field,
      });
    },
  },
};
</script>

<template>
  <v-card tile class="pb-0">
    <div>
      <v-card-title>
        <div class="text-overline">Scope</div>
      </v-card-title>
      <v-card-text :class="{ 'pb-0': $vuetify.breakpoint.smAndDown }">
        <v-text-field v-show="false" :value="currentEditDocId" disabled />
        <v-row class="px-3">
          <v-text-field
            :disabled="disabled"
            :value="number"
            class="pr-3"
            label="Analysis Number"
            @change="v => (number = v)"
          />
          <v-checkbox
            v-if="requiresApproval"
            :disabled="disabled || !isGrfn"
            :input-value="approved"
            :label="`Approved`"
            @change="v => (approved = v)"
          />
        </v-row>
        <v-text-field
          :disabled="disabled"
          :value="summary"
          label="Summary"
          @change="v => (summary = v)"
        />
        <v-select
          v-model="stream"
          :items="config.streams"
          :disabled="disabled"
          label="Stream"
        />
        <v-select
          v-if="hasValues('analyst') || editMode"
          v-model="analyst"
          :disabled="disabled"
          :items="config.analysts"
          :readonly="!editMode"
          item-text="name"
          item-value="name"
          label="Analyst"
        />
        <MeetingDatePicker
          :initial-date="meetingDate | isoDate"
          :disabled="disabled"
          @saved="onMeetingDateChanged"
        />
        <MeetingDatePicker
          v-if="hasValues('uploadDate') || editMode"
          :disabled="disabled"
          :initial-date="uploadDate | isoDate"
          label="Upload Date"
          @saved="onUploadDateChanged"
        />

        <FocusChipField
          :focus-key="`0`"
          :values-map="item"
          :previous-values-map="previousValues"
          field="attendance"
          label="Attendance Required"
        />
        <div class="notes-field">
          <v-textarea
            v-if="editMode"
            :value="notes"
            :disabled="disabled"
            label="Notes"
            @change="v => (notes = v)"
          />
          <div v-if="hasValues('notes') && !editMode" class="fake-label">
            <label>Notes</label>
            <p>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="notesFormatted" />
            </p>
          </div>
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>

<style>
.v-card__title + .v-card__text {
  padding-top: 0;
}
</style>
