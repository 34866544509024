<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'FocusChipField',
  props: {
    /**
     * The valuesMap is the list of chip values for each field in the database.
     * Any additional values are written to State and then fed back to the
     * component via this prop.
     */
    valuesMap: {
      type: Object,
      default: () => {},
    },
    /**
     * Available Items are assumed to the the unique items that can be
     * autocompleted at the data entry point.
     * Any additional values that become available in the lifetime of the
     * component will be fed into the prop.
     * The population of this list is beyond the scope of this component (thank
     * goodness) and is to be considered immutable.
     */
    availableValuesMap: {
      type: Object,
      default: () => {},
    },
    /**
     * Previously Used Items are an alternative set of values to the
     * Available Values for the items that can be autocompleted at the
     * data entry point.
     * If Available Values are provided then this set will be ignored, as such
     * it is anticipated that only one parameter will be used
     * Any additional values that become available in the lifetime of the
     * component will be fed into the prop.
     * The population of this list is beyond the scope of this component (thank
     * goodness) and is to be considered immutable.
     */
    previousValuesMap: {
      type: Object,
      default: () => {},
    },
    canAdd: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: null,
    },
    // field is the scope value field name
    field: {
      type: String,
      required: true,
    },
    // focusKey is the UID of the Scope Focus and not a keypress to change focus with.
    focusKey: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    search: null,
    menu: false,
  }),
  computed: {
    ...mapState({
      editMode: state => state.scopeEditMode,
    }),
    fieldLabel() {
      if (this.label) return this.label;
      return this.field;
    },
    items() {
      return this.valuesMap && this.valuesMap[this.field];
    },
    hasPrevious() {
      return Boolean(this.previousValuesMap);
    },
    hasAvailable() {
      return Boolean(this.availableValuesMap);
    },
    availableValues() {
      if (!this.hasAvailable && !this.hasPrevious) return;
      if (this.hasAvailable) {
        if (!this.availableValuesMap[this.field]) return;
        return [...this.availableValuesMap[this.field]].sort();
      }
      if (!this.previousValuesMap[this.field]) return;
      return [...this.previousValuesMap[this.field]].sort();
    },
    searchable() {
      return this.search && this.availableValues && this.availableValues.length;
    },
    values: {
      get() {
        return this.items;
      },
      set(value) {
        const setValue = Array.isArray(value) ? [...value] : value;
        const focusKey = this.focusKey;

        if (!focusKey || Number(focusKey) === 0) {
          this.updateFacts({
            db: this.$db,
            value: setValue,
            field: this.field,
          });
        } else {
          this.updateFocus({
            db: this.$db,
            value: setValue,
            field: this.field,
            focusKey,
          });
        }
        this.search = null;
      },
    },
    isDisabled() {
      return !this.editMode || this.disabled;
    },
    hasValues() {
      return this.values && this.values.length > 0;
    },
  },
  methods: {
    ...mapActions(['updateFocus', 'updateFacts']),
    sortChips(field) {
      if (this.valuesMap[field]) {
        this.values = [...this.valuesMap[field]].sort();
      }
    },
    editChip(chip, event) {
      const model = this;

      const parentChip = event.target.closest('.v-chip');
      const chipContent = parentChip.querySelector('strong');

      if (!chipContent) return;

      const bufferContent = document.createDocumentFragment();
      bufferContent.appendChild(chipContent);

      const newInput = document.createDocumentFragment();

      const input = document
        .createRange()
        .createContextualFragment(
          `<input class="editChip" data-prior="${chip.item}" type="text" value="${chip.item}" />`
        );

      newInput.appendChild(input);

      parentChip.classList.add('chipEditMode');
      parentChip.prepend(newInput);

      const eChip = parentChip.firstElementChild;

      eChip.addEventListener('focus', function (e) {
        const el = e.target;
        if (typeof el.selectionStart === 'number') {
          el.selectionStart = el.selectionEnd = el.value.length;
        } else if (typeof el.createTextRange !== 'undefined') {
          el.focus();
          const range = el.createTextRange();
          range.collapse(false);
          range.select();
        }
      });

      eChip.addEventListener('keydown', function (e) {
        if (e.key === 'Enter') e.target.blur();
      });

      eChip.addEventListener('blur', function (e) {
        const el = e.target;

        const parent = e.target.parentNode;
        const content = document
          .createRange()
          .createContextualFragment(`<strong>${el.value}</strong>`);
        parent.replaceChild(content, el);

        if (el.value !== chip.item) {
          const truncatedValues = [...model.values, el.value];
          truncatedValues.splice(model.values.lastIndexOf(chip.item), 1);
          model.values = [...truncatedValues];
        }

        parentChip.classList.remove('chipEditMode');
      });

      eChip.focus();
    },
    inDirectory(item) {
      return (
        this.hasPrevious ||
        (this.availableValues && this.availableValues.includes(item))
      );
    },
    removeChip(chip) {
      const truncatedValues = [...this.values];
      truncatedValues.splice(this.values.lastIndexOf(chip), 1);
      this.values = [...truncatedValues];
    },
  },
};
</script>

<template>
  <div>
    <v-combobox
      v-if="editMode || hasValues"
      v-model="values"
      :items="availableValues"
      :search-input.sync="search"
      :label="fieldLabel | snakeCaseToTitleCase"
      :ripple="false"
      :disabled="isDisabled"
      small-chips
      multiple
      @blur="sortChips(fieldLabel, $event)"
    >
      <template #no-data>
        <v-list-item v-if="searchable">
          <v-list-item-content>
            <v-list-item-title>
              No {{ label }} matching " <strong>{{ search }}</strong
              >". Press <kbd>enter</kbd> to create a new one
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template #selection="chip">
        <v-chip
          :outlined="!inDirectory(chip.item)"
          :class="{
            'grfn darken-2 directory': inDirectory(chip.item) && hasAvailable,
            'grfn darken-2 adhoc': !inDirectory(chip.item),
            'primary lighten-1 default--text previous': hasPrevious,
          }"
          :close="editMode"
          small
          label
          class="chip-value"
          close-icon="mdi-close"
          @dblclick.native="editChip(chip, $event)"
          @click:close="removeChip(chip.item)"
        >
          <strong>{{ chip.item }}</strong>
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>

<style>
.v-input .v-chip.v-chip--outlined.adhoc {
  background-color: var(--v-grfn-base) !important;
}

.v-chip.directory .v-icon,
.v-chip.adhoc .v-icon {
  transform: scale(0.75);
  color: black;
  margin-left: 0.3rem;
}

.v-application .v-select__selections input.editChip {
  /* border: 1px solid red; */
  font-weight: 600;
  caret-color: black;
  color: white;
}

.v-application .v-select__selections span.chipEditMode.v-chip {
  background-color: var(--v-secondary-base) !important;
}

.chip-value .v-chip__content {
  font-size: 11px;
  font-weight: 400;
}

.details .v-card__subtitle,
.v-card__text .v-label {
  font-weight: 200;
  color: rgba(0, 0, 0);
}
</style>
