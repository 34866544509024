<script>
import dayjs from 'dayjs';
import ScopeRow from './ScopeRow.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    ScopeRow,
    MeetingDatePicker: () => import('../components/MeetingDatePicker.vue'),
  },
  props: {},
  data() {
    return {
      stream: '',
      expanded: [],
      doc: undefined,
      toggleRow: false,
      confirmDelete: false,
      rowHover: {},
      sortBy: ['meetingDate', 'number', 'numberOfType'],
      sortDesc: [true, true, true],
      options: {
        descending: true,
        itemsPerPage: 15,
      },
      footerProps: {
        'items-per-page-options': [5, 10, 15, 20],
      },
      expand: false,
      dialog: false,
      modal: false,
      headers: [
        {
          text: '✓',
          label: 'Approved',
          value: 'approved',
          sortable: false,
          show: 'requiresApproval',
          class: 'header-approval',
        },
        {
          text: 'Id',
          value: 'number',
          filterable: false,
          sortable: false,
          label: 'Scope Id',
          class: 'header-number',
        },
        {
          text: '#',
          value: 'numberOfType',
          filterable: false,
          sortable: false,
          unit: 'Stream',
          label: 'Count of',
          class: 'header-numberOfType',
        },
        {
          text: 'Date',
          value: 'meetingDateNarrow',
          hide: 'smAndUp',
          filterable: false,
          class: 'header-workshopDateNarrow',
          label: 'Workshop Date',
        },
        {
          text: 'Date',
          value: 'meetingDate',
          hide: 'xsOnly',
          filterable: false,
          class: 'header-workshopDate',
          label: 'Workshop Date',
        },
        {
          text: 'Stream',
          value: 'stream',
          hide: 'smAndDown',
          sortable: false,
          class: 'header-stream',
        },
        {
          text: 'Summary',
          label: 'Scope Summary',
          value: 'summary',
          sortable: false,
          filterable: false,
          class: 'header-summary',
        },
        {
          value: 'scope-actions',
          sortable: false,
          filterable: false,
          class: 'header-actions',
        },
      ],
      deletedScope: {},
      editedScope: {
        project: { key: this.projectId },
        focus: '',
        number: 0,
        meetingDate: new Date().toISOString().substr(0, 10),
        stream: 'Design',
      },
      editedIndex: -1,
    };
  },
  computed: {
    ...mapState([
      'scopes',
      'route',
      'projectId',
      'isGrfn',
      'scopeEditMode',
      'scopeId',
      'currentEditDocId',
      'selectedProject',
      'config',
    ]),
    ...mapGetters([
      'newScope',
      'projectsEditable',
      'projectsOwned',
      'getProjectConfiguration',
    ]),
    requiresApproval() {
      return this.selectedProject[0] && this.selectedProject[0].approval;
    },
    scopeIdScope() {
      return this.scopes.filter(
        scope =>
          parseInt(scope.number, 10) === parseInt(this.scopeId, 10) ||
          scope.id === this.scopeId
      );
    },
    filterStreams() {
      const scopeStreamSet = new Set(this.scopes.map(scope => scope.stream));
      const streams = [...scopeStreamSet]
        .sort()
        .reverse()
        .map(stream => ({ title: stream, filter: stream }));

      return [{ title: 'All', filter: '' }, ...streams];
    },
    noData: {
      get() {
        if (this.$route.params.scopeId) {
          return `Analysis Scope ${this.projectId}-${this.$route.params.scopeId} isn't found in the registry database.`;
        }
        return `No scopes recorded for ${this.projectId}`;
      },
    },

    isOwner: {
      get() {
        return (
          this.isGrfn ||
          this.projectsOwned.filter(p => p.projectId === this.projectId)
            .length > 0
        );
      },
    },
    canEdit: {
      get() {
        return (
          this.projectsEditable.filter(p => p.projectId === this.projectId)
            .length > 0
        );
      },
    },
    filteredScopes() {
      const typeMap = {};
      const filteredScopes = [...this.scopes]
        .filter((scope, allScopes) => {
          if (this.stream) return scope.stream === this.stream;
          if (this.scopeId) return scope.number === this.scopeId;
          if (allScopes.length <= 1) return true;
          if (this.singleStream <= 2) return true;
          return scope;
        })
        .sort((a, b) => {
          const aDate = a.meetingDate,
            bDate = b.meetingDate;
          return aDate === bDate
            ? a.number === b.number
              ? 0
              : a.number > b.number
              ? -1
              : 1
            : aDate > bDate
            ? 1
            : -1;
        })
        .map(scope => {
          typeMap[scope.stream] = typeMap[scope.stream] || 0;
          scope.numberOfType = ++typeMap[scope.stream];
          return scope;
        });
      return filteredScopes;
    },
    editMode: {
      get() {
        return this.scopeEditMode;
      },
      set(v) {
        this.$store.commit('setEditMode', v);
      },
    },
    computedHeaders() {
      const bp = this.$vuetify.breakpoint;
      const vm = this;

      return this.headers.filter(h => {
        if (!h.hide && !h.show) return true;
        let hide = h.hide;
        let show = true;

        if (h.show) {
          show = this[h.show];
        }

        if (!Array.isArray(hide)) hide = [hide];
        return (
          show &&
          !hide.find(e => {
            if (typeof e === 'string') return bp[e];
            if (typeof e === 'function') return e(vm);
          })
        );
      });
    },
    registerPath() {
      if (this.$route.name === 'register-root' || !this.projectId) {
        return { name: 'register-root', params: {} };
      }
      return { name: 'register', params: { projectId: this.projectId } };
    },
    shownColumns() {
      const m = {};
      this.computedHeaders.forEach(col => {
        m[col.value] = true;
      });
      return m;
    },
    lastScope() {
      return this.filteredScopes.reduce((last, scope) => {
        if (!last.number) return scope;
        if (last.number < scope.number) return scope;
        return last;
      }, {});
    },
    newestScope() {
      return this.filteredScopes.reduce((last, scope) => {
        if (!last.meetingDate) return scope;
        if (last.meetingDate.toDate() < scope.meetingDate.toDate())
          return scope;
        return last;
      }, {});
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Scope' : 'Edit Scope';
    },

    analysisUnits() {
      return this.getProjectConfiguration.analysisUnits;
    },
    projectConfig() {
      return this.getProjectConfiguration;
    },
    multiStream() {
      return this.filterStreams && this.filterStreams.length > 2;
    },
    singleStream() {
      return this.filterStreams && this.filterStreams.length <= 2;
    },
    scopesByType() {
      return [...this.filteredScopes].reduce((typeMap, scope) => {
        typeMap[scope.stream] = {
          count: 0,
          planned: 0,
          ...typeMap[scope.stream],
        };
        scope.planned = scope.meetingDate.toDate() > new Date();
        if (scope.planned) {
          scope.plannedOfType = ++typeMap[scope.stream].planned;
        } else {
          scope.numberOfType = ++typeMap[scope.stream].count;
        }
        return typeMap;
      }, {});
    },
    expandedRows: {
      get() {
        return this.scopeIdScope[0] ? this.scopeIdScope : this.expanded;
      },
      set() {},
    },
    streams() {
      const scopeStreamSet = this.scopes
        ? new Set([...this.scopes.map(scope => scope.stream)])
        : new Set();
      const projectStreamSet = this.getProjectConfiguration.analysisUnits
        ? new Set([...this.getProjectConfiguration.analysisUnits])
        : new Set();

      return [...new Set(scopeStreamSet, projectStreamSet).values()].sort();
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.close();
      }
    },
    projectConfig(config) {
      // On the unlikely situation of the Stream title
      // changing while someone is watching.
      const headers = this.headers.map(header => {
        if (header.value === 'stream') {
          header.text = config.streamsTitle || 'Stream';
        }
        if (header.value === 'numberOfType') {
          header.unit = config.streamsTitle || 'Stream';
        }
        return header;
      });
      this.headers = headers;
    },
    filteredScopes(scopes) {
      if (scopes.length === 1) {
        // present the expanded single scope if there is only one row available.
        this.expanded = [scopes[0]];
      }
      if (scopes.length === 0) {
        // scopes length reduces to zero as the project is changed but not when project is filtered.
        this.stream = '';
        this.expanded = [];
      }
    },
    editMode(on) {
      if (on) {
        this.$store.dispatch('copyToHistory', {
          db: this.$db,
          doc: { id: this.currentEditDocId },
        });
      }
    },
    ...mapGetters(['getProjectConfiguration', 'appConfig']),
  },
  mounted() {
    const headers = this.headers.map(header => {
      if (header.value === 'stream') {
        header.text = this.projectConfig.streamsTitle || 'Stream';
      }
      if (header.value === 'numberOfType') {
        header.unit = this.projectConfig.streamsTitle || 'Stream';
      }
      return header;
    });
    this.headers = headers;
  },

  methods: {
    isToday(date) {
      if (!date) return;
      const d = dayjs(date.toDate());
      return d.isSame(dayjs(), 'date');
    },
    isPast(date) {
      if (!date) return;

      const scopeDate = date.toDate().getTime();

      return scopeDate < new Date().getTime();
    },
    streamFilter(stream) {
      this.stream = stream;
    },
    expandRow(item, _expanded, e) {
      if (!_expanded) {
        console.log(item.id);
        this.$vuetify.goTo(e.target);
      }

      if (!this.scopeEditMode) {
        if (!this.scopeId) {
          this.expanded = item === this.expanded[0] ? [] : [item];
        }

        this.currentlyExpanded = [...this.expanded];
      }
    },
    triggerEditMode(item) {
      this.$store.dispatch('triggerEditMode', item.id);
    },
    doNothing() {
      return false;
    },
    allowOnlyWeekdays(val) {
      const m = dayjs(val);
      const dow = m.day();
      return dow > 0 && dow < 6;
    },
    showNewScopeCard() {
      this.editedScope = { ...this.$store.getters.newScope };
      this.dialog = true;
    },
    copyScope(item) {
      const newDate = new Date(this.newestScope.meetingDate.toDate());
      newDate.setDate(newDate.getDate() + 14);

      const duplicateScope = {
        ...this.$store.getters.defaultScope,
        project: {
          key: item.project.key,
          name: item.project.name,
        },
        stream: item.stream,
        summary: `Copy of ${item.summary}`,
        focus: item.focus,
        meetingDate: newDate,
        number: this.scopes.reduce((number, scope) => {
          if (number <= scope.number) return scope.number + 1;
          return number;
        }, 1),
      };

      this.$store
        .dispatch('addNewScope', { db: this.$db, newScope: duplicateScope })
        .then(() => {
          this.$store.commit('cancelEditMode');

          this.expandRow(this.newestScope);
        });
    },
    addScope() {
      this.$store
        .dispatch('addNewScope', { db: this.$db, newScope: this.editedScope })
        .then(() => {
          this.close();
        });
    },
    close() {
      this.dialog = false;
      this.confirmDelete = false;
    },
    cancelDelete() {
      // this.$store.commit("cancelEditMode");
      this.confirmDelete = false;
    },
    deleteScope() {
      this.$store
        .dispatch('deleteScope', {
          db: this.$db,
          doc: { id: this.currentlyExpanded[0].id },
        })
        .then(() => {
          this.$store.commit('cancelEditMode');
          this.confirmDelete = false;
        });
    },
    onMeetingDateChanged(e) {
      this.editedScope.meetingDate = new Date(e);
    },
    filterByType(stream) {
      if (this.stream === stream) {
        this.stream = '';
        this.expanded = [];
      } else {
        this.stream = stream;
      }
    },
  },
};
</script>

<template>
  <div>
    <v-row>
      <v-col class="pr-6 pt-3 px-3">
        <!-- Data Table -->
        <v-data-table
          v-if="$store.state.projectId"
          ref="analysisRegister"
          :class="{
            'scope-table-narrow': $vuetify.breakpoint.smAndDown,
            'requires-approval': requiresApproval,
            'multi-stream': multiStream,
            'single-stream': singleStream,
          }"
          :hide-default-header="Boolean($route.params.scopeId)"
          :hide-default-footer="Boolean($route.params.scopeId)"
          :items="filteredScopes"
          :headers="computedHeaders"
          :options.sync="options"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :footer-props="footerProps"
          class="scope-table"
          :no-data-text="noData"
          fixed-header
          :expanded.sync="expandedRows"
          must-sort
          multi-sort
          lg9
          item-key="id"
        >
          <!-- Approved Column -->
          <template #[`header.approved`]="{ header }">
            <span
              v-if="requiresApproval"
              :title="header.label"
              class="header-requiresApproval"
              >{{ header.text }}</span
            >
          </template>

          <!-- Number Headers -->
          <template #[`header.number`]="{ header }">
            <span :title="header.label">{{ header.text }}</span>
          </template>
          <template #[`header.numberOfType`]="{ header }">
            <span :title="`${header.unit} type count`">{{ header.text }}</span>
          </template>
          <template #[`header.summary`]="{ header }">
            <span :title="header.label">{{ header.text }}</span>
          </template>
          <template #[`header.meetingDate`]="{ header }">
            <span :title="header.label">{{ header.text }}</span>
          </template>

          <!-- Stream Column Header -->
          <template #[`header.stream`]="{ header }">
            <v-menu :disabled="scopeEditMode" bottom offset-y>
              <template #activator="{ on }">
                <span :title="header.label" v-on="on">{{ header.text }}</span>
              </template>

              <v-list>
                <v-list-item
                  v-for="(filteredStream, s) in filterStreams"
                  :key="s"
                  @click="streamFilter(filteredStream.filter)"
                >
                  <v-list-item-title>
                    {{ filteredStream.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <!-- Table Scope Actions -->
          <template #[`header.scope-actions`]>
            <v-btn
              v-if="canEdit && !scopeId"
              :disabled="scopeEditMode"
              aria-label="Add an Analysis Scope"
              right
              color="text"
              small
              icon
              class="addFocus"
              @click="showNewScopeCard"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>

          <!-- Expanded Row -->
          <template #expanded-item="{ item, headers: h }">
            <tr class="expanded-row">
              <td :colspan="h.length">
                <ScopeRow
                  :expanded-item="expanded[0]"
                  :project-key="$store.state.projectId"
                  :scope-item="item"
                  :scope-number="item.number"
                  :doc="item"
                />
              </td>
            </tr>
          </template>

          <!-- Customised Footer -->
          <template #footer="">
            <div class="footer-counts">
              <span
                v-for="({ count, planned }, type) in scopesByType"
                :key="type"
                class="scope-type-tally"
              >
                <span class="scopeType" @click="filterByType(type)">{{
                  type
                }}</span>
                <span
                  :title="`No. ${type} performed.`"
                  class="scopeTypeCount"
                  >{{ count }}</span
                >
                <span
                  v-if="planned > 0"
                  :title="`No. ${type} planned.`"
                  class="scopeTypePlannedCount"
                  >{{ planned }}</span
                >
              </span>
            </div>
          </template>

          <!-- Customised Row Item -->
          <template #item="{ item, isExpanded }">
            <tr
              v-if="
                !$route.name.includes('scope') ||
                $store.getters.projectsViewable.length
              "
              :class="{
                'lighten-5': !isExpanded || scopeId,
                past: isPast(item.meetingDate),
                'primary lighten-1': isExpanded && !scopeId,
                'hidden-print-only': isExpanded,
              }"
              class="scope-row"
              style="cursor: pointer"
              @click.stop="expandRow(item, isExpanded, $event)"
            >
              <template v-if="!scopeId">
                <td v-if="requiresApproval" class="narrow">
                  {{ (item.approved && '✓') || '-' }}
                </td>
                <td class="narrow" px-0>
                  <span title="Analysis Number">{{ item.number }}</span>
                </td>
                <td v-if="shownColumns.meetingDateNarrow" class="narrow">
                  {{ item.meetingDate | isoDate | trim }}
                </td>
                <td v-if="shownColumns.meetingDate" class="narrow">
                  {{ item.meetingDate | isoDate }}
                </td>
                <td v-if="multiStream" class="narrow">
                  <span class="streamHover"
                    >{{ item.stream
                    }}<span
                      :title="`# of ${item.stream}`"
                      class="streamCount"
                      >{{ item.numberOfType }}</span
                    ></span
                  >
                </td>
                <td class="scope-summary">{{ item.summary }}</td>
              </template>
              <td
                title="Scope Actions"
                class="text-right scope-actions hidden-print-only"
              >
                <v-row no-gutters justify="end">
                  <v-spacer />
                  <v-col
                    v-if="
                      isExpanded &&
                      !editMode &&
                      filteredScopes.length >= 1 &&
                      !$route.params.scopeId &&
                      (!isPast(item.meetingDate) || isToday(item.meetingDate))
                    "
                  >
                    <v-btn
                      :to="{
                        name: 'scope',
                        params: {
                          projectId: $store.state.projectId,
                          scopeId: item.number,
                        },
                      }"
                      aria-label="View Scope as a standalone page."
                      small
                      title="View Scope as a standalone page."
                      icon
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="
                      isExpanded &&
                      !editMode &&
                      $store.getters.projectsViewable.length > 0 &&
                      isPast(item.meetingDate)
                    "
                  >
                    <v-btn
                      :href="`https://grfn.atlassian.net/issues/?jql=project%20%3D%20${projectId}%20AND%20issuetype%20not%20in%20(%22Parent%20Issue%22)%20AND%20%22Analysis%20Number%22%20%3D%20%22${expandedRows[0].number}%22%20ORDER%20BY%20priority%20DESC%2C%20updated%20DESC`"
                      aria-label="Show Issues Raised in Jira"
                      title="Show Issues Raised in Jira"
                      small
                      icon
                      target="_blank"
                    >
                      <v-icon>mdi-clipboard-text</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="isExpanded && !editMode && $route.params.scopeId"
                  >
                    <v-btn
                      :to="registerPath"
                      small
                      icon
                      title="Show Scope in Register"
                      aria-label="Show Scope in Register"
                    >
                      <v-icon>mdi-clipboard-arrow-left-outline</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="
                      isExpanded &&
                      !editMode &&
                      $store.getters.projectsViewable.length > 0
                    "
                  >
                    <v-btn
                      :to="{
                        name: 'schedule',
                        params: {
                          projectId: $store.state.projectId,
                          scopeId: item.number,
                          focusDay: item.meetingDate.toDate(),
                        },
                      }"
                      aria-label="Show Analysis in Schedule"
                      title="Show Analysis in Schedule"
                      small
                      icon
                    >
                      <v-icon>mdi-calendar-today</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="
                      isExpanded &&
                      !editMode &&
                      filteredScopes.length > 1 &&
                      !$route.params.scopeId
                    "
                  >
                    <v-btn
                      aria-label="Copy Analysis Scope as a new entry"
                      title="Copy Analysis Scope as a new entry"
                      color="action"
                      small
                      icon
                      class="copyFocus"
                      @click.stop="copyScope(item)"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col v-if="isExpanded && editMode">
                    <v-btn
                      v-show="isOwner"
                      aria-label="Confirm Delete"
                      title="Confirm Delete"
                      small
                      class="black--text"
                      icon
                      @click.stop="confirmDelete = true"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col v-if="isExpanded && !editMode">
                    <v-btn
                      v-show="canEdit"
                      aria-label="Edit Scope"
                      title="Edit Scope"
                      small
                      class="action--text"
                      icon
                      @click.stop="triggerEditMode(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col v-if="isExpanded && editMode">
                    <v-btn
                      small
                      color="action"
                      icon
                      aria-label="Exit edit mode"
                      title="Exit edit mode"
                      @click.stop="$store.commit('cancelEditMode')"
                    >
                      <v-icon>mdi-content-save-check</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Create New Scope Dialog -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  :value="editedScope.project.key"
                  disabled
                  label="Project"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="editedScope.number" label="Number" />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <MeetingDatePicker
                  :initial-date="editedScope.meetingDate | isoDate"
                  :disabled="modal"
                  @saved="onMeetingDateChanged"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedScope.summary"
                  label="Summary Focus"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="editedScope.stream"
                  :items="streams"
                  label="Stream"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="cancel darken-4" text @click="close">Cancel</v-btn>
          <v-btn text color="action" @click="addScope">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirm Delete Dialog Button -->
    <v-row justify="center">
      <v-dialog v-model="confirmDelete" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5" color="primary">
            Really delete this?
          </v-card-title>
          <v-card-text>
            This cannot be undone, the database connection is real-time and no
            chance to restore backups.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="error darken-3" text dark @click="deleteScope()">
              Delete
            </v-btn>
            <v-btn color text @click="cancelDelete()">Keep</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
//#region Scope Row
.scope-row {
  position: relative;
  &:hover .action-icon {
    display: inline-flex;
  }
  .expanded-row td {
    padding: 0;
  }

  .scope-summary {
    font-weight: 400;
  }
}
//#endregion

//#region Buttons
.v-btn--icon {
  width: 18px;
}
.v-btn--active:before,
.v-btn:hover:before,
.v-btn:focus:before {
  background-color: transparent;
}
//#endregion

//#region Scope Table Theme
.scope-table .theme--light.v-sheet.scope-expand,
.scope-table .theme--light.v-table,
.scope-table .theme--light.v-datatable .v-datatable__actions {
  background-color: var(--v-primary-lighten4);
}
.scope-table .theme--light.v-chip {
  background-color: var(--v-primary-base);
}

.scope-table .analysis-log .theme--light.v-chip {
  background-color: var(--v-tertiary-darken4);
  color: white;
}

.scope-table .theme--light.v-table tbody tr.scope-row {
  border-bottom: var(--v-grfn-darken4) 1px solid;
}
//#endregion

//#region Scope Action Buttons
.scope-actions {
  position: relative;
  .row {
    padding-right: 0.5rem;
    flex-wrap: nowrap;
  }
  .col {
    text-align: right;
    max-width: 3em;
    min-width: 2em;
  }
  .action-icon {
    display: none;
  }
  .v-input--switch .v-input__slot {
    margin-bottom: 0;
    margin-top: 10px;
  }
  .v-btn {
    transform: scale(0.7);
  }
}
//#endregion

.v-data-table {
  tr:not(.past) + tr.past td {
    border-top-style: double;
    border-top-color: var(--v-primary-base);

    &:last-child::after {
      content: 'today';
      font-size: x-small;
      text-align: center;
      position: absolute;
      top: -0.1rem;
      transform: translateY(-100%);
      text-transform: uppercase;
      right: 14px;
      color: var(--v-grfn-darken4);
      letter-spacing: 0.1rem;
    }
  }
  //#region table headers
  .addFocus {
    transform: translate(-50%, -45%) scale(0.8);
    position: absolute;
    right: 12.5px;
  }
  #{$v-deep} {
    .v-data-table-header__sort-badge {
      display: none;
    }
    .header-number,
    .header-numberOfType {
      min-width: 50px;
      max-width: 65px;
    }
    .header-workshopDate,
    .header-stream {
      width: 12.5%;
      min-width: 135px;
    }
  }
  .header-number,
  .header-numberOfType {
    display: none;
  }

  &.multi-stream {
    #{$v-deep} {
      .header-numberOfType {
        display: none;
      }
    }
    .header-number {
      display: inherit;
    }
  }
  &.single-stream {
    #{$v-deep} {
      .header-stream {
        display: none;
      }
      .header-numberOfType {
        display: none;
      }
    }
    .header-number {
      display: inherit;
    }
  }
  .multiStreamCount {
    margin-left: 0.2rem;
    font-size: 0.65rem;
    font-style: italic;
    white-space: nowrap;
    display: none;

    &::before {
      content: '(';
    }
    &::after {
      content: ')';
    }
  }
  tr:hover {
    .streamCount {
      color: var(--v-secondary-base);
      opacity: 1;
    }
  }
  .streamCount {
    font-size: 0.85rem;
    &::before {
      margin-left: 0.5rem;
      content: '(';
    }
    &::after {
      content: ')';
    }
    opacity: 0.35;
  }
  //#endregion

  //#region table footer
  #{$v-deep} {
    .v-data-footer {
      margin-right: 0;
    }
  }
  .footer-counts {
    position: absolute;
    margin: 24px;
    margin-left: 16px;
    font-size: 12px;
    line-height: 12px;
  }
  &.single-stream {
    .footer-counts {
      display: none;
    }
  }
  .scope-type-tally {
    display: inline-block;
    height: 32px;
  }
  .scopeType {
    margin-left: 0.5rem;
    margin-right: 0.2rem;
    cursor: pointer;

    &::after {
      content: ':';
    }
  }
  .scopeTypeCount,
  .scopeTypePlannedCount {
    font-weight: bolder;
  }
  .scopeTypePlannedCount {
    &::after {
      content: ')';
    }
    &::before {
      content: '(';
      margin-left: 0.05rem;
    }
  }
  //#endregion

  #{$v-deep} {
    &.expanded-row {
      background: var(--v-grfn-base);

      &:hover {
        background: var(--v-grfn-base) !important;
      }
    }
  }
}
</style>

<style>
.scope-facts .v-text-field__slot input,
.details .v-text-field__slot input,
.v-select__selection {
  font-size: 14px;
}
</style>
