var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scope-row expanded-scope"},[_c('v-row',{staticClass:"pb-3 xs12",staticStyle:{"position":"relative"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-container',{class:{
          'pr-1': _vm.$vuetify.breakpoint.mdAndUp,
          'pb-2': _vm.$vuetify.breakpoint.smAndDown,
        },attrs:{"grid-list-xs":""}},[_c('v-row',[_c('v-col',{staticClass:"scope-facts",attrs:{"cols":"12"}},[_c('ScopeFactsCard',{attrs:{"item":_vm.scopeItem,"focus-key":_vm.orderedFocus[0].key}}),(_vm.orderedFocus.length > 1)?_c('v-card',{staticClass:"mt-3 agenda",attrs:{"tile":""}},[_c('v-card-title',{staticClass:"text-overline mb-0 pb-0"},[_vm._v("Agenda")]),_c('v-card-text',{staticClass:"mt-0"},[_c('ol',_vm._l((_vm.orderedFocus),function(focus,f){return _c('li',{key:focus.key,staticClass:"scope-focus",attrs:{"md6":_vm.focusCount > 1,"sm12":_vm.focusCount <= 1,"xs12":""},on:{"click":function($event){return _vm.scrollToDetail(f)}}},[_c('span',[_vm._v(_vm._s(focus.name))]),_c('span',{staticClass:"agenda-type"},[_vm._v("["+_vm._s(focus.type)+"]")])])}),0)])],1):_vm._e()],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-container',{class:{
          'pl-1': _vm.$vuetify.breakpoint.mdAndUp,
          'pt-1': _vm.$vuetify.breakpoint.smAndDown,
        },attrs:{"grid-list-md":""}},[_c('v-row',{staticClass:"details"},_vm._l((_vm.orderedFocus),function(focus,n){return _c('v-col',{key:focus.key,staticClass:"scope-focus",attrs:{"md":"6","sm":"12","cols":"12"}},[_c('ScopeFocusCard',{attrs:{"focus-count":_vm.focusCount,"focus-key":focus.key,"index":n,"item":focus,"doc":_vm.parentDoc}})],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }