<script>
import FocusChipField from './FocusChipField.vue';
import Vuex from 'vuex';

export default {
  name: 'ScopeFocusCard',
  components: { FocusChipField },
  props: {
    item: { type: Object, default: () => {} },
    doc: { type: Object, default: () => {} },
    index: { type: Number, default: 0 },
    focusCount: { type: Number, default: 0 },
  },
  data: () => ({}),
  computed: {
    ...Vuex.mapState({
      availableValues: state => (state.selectedProject[0] || {}).fieldValues,
      previousValues: state => (state.selectedProject[0] || {}).usedFieldValues,
      projectId: state => state.projectId,
      editMode: state => state.scopeEditMode,
    }),
    ...Vuex.mapGetters(['getProjectConfiguration']),
    analysisTypes() {
      return this.getProjectConfiguration.analysisTypes;
    },
    focusKey() {
      return this.item && this.item.key;
    },
    hasDetails() {
      return Object.keys(this.item).length > 1;
    },
    analysisType: {
      get() {
        return this.item.type;
      },
      set(type) {
        this.updateFocus('type', type);
      },
    },
    color() {
      if (!this.editMode) return 'grey lighten-4';
      return 'grey lighten-2';
    },
    disabled() {
      return !this.editMode;
    },
    notes: {
      get() {
        return this.item.notes;
      },
      set(value) {
        this.updateFocus('notes', value.trim());
      },
    },
    notesFormatted() {
      return this.notes.replace(/[\r\n]/g, '<br />');
    },
    name: {
      get() {
        return this.item.name;
      },
      set(v) {
        this.updateFocus('name', v.trim());
      },
    },
  },
  methods: {
    ...Vuex.mapGetters(['available']),
    copyFocus() {
      const newKey = new Date().getTime();
      const sourceFocus = {
        ...this.item,
        name: `Copy of ${this.item.name}`,
        key: newKey,
      };
      this.addFocus({ key: newKey, focus: sourceFocus });
    },
    shiftFocus({ direction }) {
      const focusList = this.$parent.orderedFocus;
      const targetIndex = this.index + (direction === 'up' ? 1 : -1);

      const source = focusList[this.index],
        target = focusList[targetIndex];

      this.$store.dispatch('swapFocus', { db: this.$db, source, target });
    },
    addFocus({ key, focus }) {
      this.$store.dispatch('addFocus', { db: this.$db, key, focus });
    },
    updateFocus(field, value) {
      this.$store.dispatch('updateFocus', {
        db: this.$db,
        value: value,
        field: field,
        focusKey: this.focusKey,
      });
    },
    hasValues(field) {
      return this.item[field] && this.item[field].length > 0;
    },
  },
};
</script>

<template>
  <v-card height="100%" tile color="grfn lighten-1">
    <v-card-title>
      <div class="text-overline">
        Detail {{ `${focusCount > 1 ? index + 1 + ' ' : ''}` }}
      </div>
    </v-card-title>
    <v-card-text>
      <span v-if="!hasDetails && !editMode" class="grey--text"
        >This Scope entry is awaiting details.</span
      >
      <v-text-field
        v-if="focusCount > 1"
        :disabled="disabled"
        :value="name"
        label="Title"
        @change="v => (name = v)"
      />

      <v-select
        v-if="hasValues('type') || editMode"
        :disabled="disabled"
        :value="analysisType"
        :items="analysisTypes"
        :readonly="!editMode"
        label="Analysis Type"
        @change="v => (analysisType = v)"
      />

      <FocusChipField
        :values-map="item"
        :available-values-map="availableValues"
        :focus-key="focusKey"
        field="programmatic_zones"
      />
      <FocusChipField
        :values-map="item"
        :available-values-map="availableValues"
        :focus-key="focusKey"
        field="levels"
      />

      <FocusChipField
        v-if="projectId !== 'LST'"
        :values-map="item"
        :available-values-map="availableValues"
        :focus-key="focusKey"
        field="construction_zones"
      />

      <FocusChipField
        :values-map="item"
        :available-values-map="availableValues"
        :focus-key="focusKey"
        field="disciplines"
      />

      <FocusChipField
        v-if="projectId !== 'LST'"
        :values-map="item"
        :available-values-map="availableValues"
        :focus-key="focusKey"
        field="workpackages"
      />

      <FocusChipField
        :focus-key="focusKey"
        :values-map="item"
        :previous-values-map="previousValues"
        field="tests"
        label="Tests"
      />

      <FocusChipField
        :values-map="item"
        :previous-values-map="previousValues"
        :focus-key="focusKey"
        field="models"
      />

      <div class="notes-field">
        <v-textarea
          v-if="editMode"
          :value="notes"
          :disabled="disabled"
          label="Notes"
          @change="v => (notes = v)"
        />
        <div v-if="hasValues('notes') && !editMode" class="fake-label">
          <label>Notes</label>
          <p>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="notesFormatted" />
          </p>
        </div>
      </div>
    </v-card-text>

    <v-card-actions text-align-right>
      <v-btn
        v-show="focusCount > 1 && index > 0"
        v-if="editMode"
        title="Sort Detail Lower"
        class="unshiftFocus sort"
        text
        icon
        small
        absolute
        bottom
        left
        @click="shiftFocus({ direction: 'down', index })"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
        v-show="focusCount > 1 && index < focusCount - 1"
        v-if="editMode"
        title="Sort Detail Higher"
        class="shiftFocus sort"
        text
        icon
        small
        absolute
        bottom
        left
        @click="shiftFocus({ direction: 'up', index })"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn
        v-if="editMode"
        title="Copy Scope Detail"
        color="grfn darken-4"
        small
        absolute
        bottom
        right
        text
        icon
        class="copyFocus"
        @click="copyFocus"
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
      <v-btn
        v-if="editMode"
        title="Postpone Scope Detail To Next"
        color="grfn darken-4"
        disabled
        small
        absolute
        bottom
        right
        text
        icon
        class="delayFocus"
      >
        <v-icon>mdi-timetable</v-icon>
      </v-btn>
      <v-btn
        v-show="editMode && focusCount > 1"
        class="removeFocus"
        text
        title="Discard Detail"
        icon
        dark
        small
        color="action"
        absolute
        bottom
        right
        @click="$store.dispatch('removeFocus', { db: $db, doc, focusKey })"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="editMode && focusCount === index + 1"
        small
        title="Add Scope Detail"
        absolute
        bottom
        right
        icon
        text
        class="addFocus"
        @click="addFocus"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.scope-focus:first-child .v-card__actions button.shiftFocus {
  transform: translateX(-20%);
}

.scope-focus .v-card__actions button.shiftFocus {
  transform: translateX(50%);
}

.scope-focus:last-child .v-card__actions button.removeFocus {
  transform: translateX(-105%);
}
.scope-focus:last-child .v-card__actions button.copyFocus {
  transform: scale(0.85) translateX(-240%);
}
.scope-focus:last-child .v-card__actions button:nth-last-child(1) {
  transform: translateX(0);
}

.scope-focus .v-card__actions button.copyFocus {
  transform: scale(0.85) translateX(-120%);
}

.scope-focus .v-card__actions button.delayFocus {
  transform: scale(0.85) translateX(-240%);
}
.scope-focus:last-child .v-card__actions button.delayFocus {
  transform: scale(0.85) translateX(-360%);
}

v-card-subtitle {
  position: relative;
  top: -16px;
}

v-card-subtitle:last-child {
  top: 0;
}
</style>

<style>
.fake-label {
  position: relative;
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.75rem;
  line-height: 16px;
  margin-top: 6px;
  padding-top: 6px;
}

.fake-label label {
  transform: translateY(-18px) scale(0.75);
  font-size: 16px;
  left: 0px;
  right: auto;
  position: absolute;
  transform-origin: top left;
  height: 20px;
  line-height: 20px;
  top: 6px;
  color: rgba(0, 0, 0, 0.38);
}
</style>
