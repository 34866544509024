<script>
import ScopeFocusCard from './ScopeFocusCard.vue';
import ScopeFactsCard from './ScopeFactsCard.vue';
import * as easings from 'vuetify/es5/services/goto/easing-patterns';

export default {
  name: 'ScopeRow',
  components: { ScopeFocusCard, ScopeFactsCard },
  props: {
    scopeNumber: { type: [String, Number], default: 0 },
    projectKey: { type: String, default: '' },
    scopeItem: { type: Object, default: () => {} },
    doc: { type: Object, default: () => {} },
    expandedItem: { type: Object, default: () => {} },
  },
  data() {
    return {
      confirmDelete: false,
      easings: Object.keys(easings),
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic',
    };
  },
  computed: {
    projectFields() {
      return this.$store.getters.projectFields();
    },
    editMode() {
      return this.$store.state.scopeEditMode;
    },
    parentDoc() {
      return this.doc;
    },
    focusCount() {
      if (Array.isArray(this.scopeItem.focus))
        return this.scopeItem.focus.length;
      if (typeof this.scopeItem.focus === 'string')
        return this.scopeItem.focus.length;
      return Object.keys(this.scopeItem.focus).length;
    },
    orderedFocus() {
      const sortByKey = (a, b) => {
        const A = a.key,
          B = b.key;
        return A === B ? 0 : A > B ? 1 : -1;
      };
      const ordered = Object.entries(this.scopeItem.focus)
        .map(e => ({ ...e[1], key: e[0] }))
        .sort(sortByKey);
      return ordered;
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      };
    },
  },
  created() {},
  methods: {
    addFocus() {
      this.$store.dispatch('addFocus', { db: this.$db });
    },
    deleteScope() {
      this.$store.dispatch('deleteScope', { db: this.$db, doc: this.doc });
    },
    scrollToDetail(detailId) {
      const detailWrapper = document.querySelector('.details');
      const scopeDetails = detailWrapper.querySelectorAll('.scope-focus');
      const scope = scopeDetails[detailId];
      const options = { ...this.options, container: detailWrapper };

      this.$vuetify.goTo(scope, options);
    },
  },
};
</script>

<template>
  <div class="scope-row expanded-scope">
    <v-row style="position: relative" class="pb-3 xs12">
      <v-col cols="12" md="4">
        <v-container
          :class="{
            'pr-1': $vuetify.breakpoint.mdAndUp,
            'pb-2': $vuetify.breakpoint.smAndDown,
          }"
          grid-list-xs
        >
          <v-row>
            <v-col cols="12" class="scope-facts">
              <ScopeFactsCard
                :item="scopeItem"
                :focus-key="orderedFocus[0].key"
              />
              <v-card v-if="orderedFocus.length > 1" tile class="mt-3 agenda">
                <v-card-title class="text-overline mb-0 pb-0"
                  >Agenda</v-card-title
                >
                <v-card-text class="mt-0">
                  <ol>
                    <li
                      v-for="(focus, f) in orderedFocus"
                      :key="focus.key"
                      :md6="focusCount > 1"
                      :sm12="focusCount <= 1"
                      xs12
                      class="scope-focus"
                      @click="scrollToDetail(f)"
                    >
                      <span>{{ focus.name }}</span>
                      <span class="agenda-type">[{{ focus.type }}]</span>
                    </li>
                  </ol>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" md="8">
        <v-container
          :class="{
            'pl-1': $vuetify.breakpoint.mdAndUp,
            'pt-1': $vuetify.breakpoint.smAndDown,
          }"
          grid-list-md
        >
          <v-row class="details">
            <v-col
              v-for="(focus, n) in orderedFocus"
              :key="focus.key"
              md="6"
              sm="12"
              cols="12"
              class="scope-focus"
            >
              <ScopeFocusCard
                :focus-count="focusCount"
                :focus-key="focus.key"
                :index="n"
                :item="focus"
                :doc="parentDoc"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.agenda li {
  font-size: 0.75rem;
  cursor: pointer;
}
.agenda-type {
  text-transform: uppercase;
  font-size: 0.7em;
  /* padding-left: 0.2rem; */
  display: block;
  padding-right: 0.5rem;
  transform: translateY(-0.1rem);
  padding-bottom: 0.2rem;
}

.agenda li span:first-child {
  /* color: var(--v-secondary-darken3); */
  transform: translateY(0.05rem) !important;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
  display: block;
  font-size: 1em;
  font-weight: 500;
}

.details {
  max-height: calc(100vh - 124px - 88px);
  overflow-y: scroll;

  &:hover {
    overflow-y: scroll !important;

    &::-webkit-scrollbar-thumb {
      background-color: var(--v-primary-darken2);
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-primary-base);
  }
}
</style>
